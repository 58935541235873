import React from 'react'
import styled from 'styled-components';
import { Link, StaticQuery, graphql } from 'gatsby'

const LISTING_QUERY = graphql`
  query BlogPostListing {
    allMarkdownRemark(
      limit: 15
      sort: { order: DESC, fields: [frontmatter___date] }
    ) {
      edges {
        node {
          excerpt
          frontmatter {
            date(formatString:"DD MMMM, YYYY")
            title
            slug
          }
        }
      }
    }
  }
`

const Post = styled.article`
box-shadow:0px 3px 10px rgba(25,16,34,0.05);
padding:1rem;
border-radius:4px;
margin-bottom:1rem;
a { 
  color: #000;
  text-decoration:none;
}
p {
  font-size:0.8rem;
}
h2 {
  margin-bottom:0;
}
.readmore {
  font-family:-apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen,
    Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
    font-size:0.7rem;
    text-decoration:underline;
    text-transform:uppercase;
    font-weight:bold;
    color:#818999;
    }
`;

const Listing = () => (
  <StaticQuery
    query={LISTING_QUERY}
    render={({ allMarkdownRemark }) =>
      allMarkdownRemark.edges.map(({ node }) => (
        <Post key={node.frontmatter.slug}>
          <Link to={`/posts${node.frontmatter.slug}`}>
            <h2>{node.frontmatter.title}</h2>
          </Link>
          <p>{node.frontmatter.date}</p>
          <p>{node.excerpt}</p>
          <Link className="readmore" to={`/posts${node.frontmatter.slug}`}>Read More</Link>
        </Post>
      ))
    }
  />
)

export default Listing
